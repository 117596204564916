import React from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import ProjectCard from "../projects/project"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import { Link } from "gatsby"
export default function Related({
  projects,
  bgColor = "bg-white",
  title = "Related Projects",
  children,
}) {
  return (
    <section className={`relative ${bgColor} py-12 md:py-20`}>
      <div className="container">
        <div className="flex justify-between flex-wrap gap-5 lg:flex-row md:flex-row lg:items-center md:items-center flex-col mb-10">
          <h2>{title}</h2>
          <Link to="/Projects" className="text-sm text-purple-600 font-medium">
            <FontAwesomeIcon className="mr-2" icon={faArrowRight} />
            View all projects
          </Link>
        </div>
        <TransitionGroup className="grid lg:grid-cols-3 md:grid-cols-3 gap-5">
          {projects.map(project => (
            <CSSTransition key={project.title} timeout={500} classNames="item">
              <ProjectCard project={project} />
            </CSSTransition>
          ))}
        </TransitionGroup>
        {children}
      </div>
    </section>
  )
}
