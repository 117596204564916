import React, { useState, useEffect } from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Impact from "./impact"
import Testimonial from "./testimonial"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMouseAlt,
  //faExternalLinkSquare
} from "@fortawesome/pro-light-svg-icons"
import {
  //faMouseAlt,
  faExternalLinkSquare,
} from "@fortawesome/pro-regular-svg-icons"
import Visuals from "./visual"
import Services from "./services"
import Related from "./related"
import Publications from "./publications"

export default function Project({ pageContext: project }) {
  const [isScrollVisible, setScrollVisible] = useState(true)

  useEffect(() => {
    document.addEventListener("scroll", _ => {
      if (window.scrollY > 10) {
        setScrollVisible(false)
      }
    })
  }, [isScrollVisible])

  function getClient() {
    if (!project.client) return false
    if (!project.client.img)
      return <p className="font-light text-sm">{project.client.name}</p>
    return (
      <img
        className="h-12 object-contain"
        alt={project.client.name}
        title={project.client.name}
        src={`https://storage.googleapis.com/keshif-website/Logos/${project.client.img}_BW.png`}
      />
    )
  }

  function getFunder() {
    if (!project.funder) return false
    if (!project.funder.img)
      return <p className="font-light text-sm">{project.funder.name}</p>
    return (
      <img
        className="h-12 object-contain"
        alt={project.funder.name}
        title={project.funder.name}
        src={`https://storage.googleapis.com/keshif-website/Logos/${project.funder.img}_BW.png`}
      />
    )
  }

  return (
    <Layout>
      <Seo
        description={project.description}
        meta={[
          {
            property: `og:image`,
            content: `https://storage.googleapis.com/keshif-website/Projects/${project.slug}/Main.png`,
          },
          {
            property: `twitter:image`,
            content: `https://storage.googleapis.com/keshif-website/Projects/${project.slug}/Main.png`,
          },
        ]}
        title={project.title.replace(/(<([^>]+)>)/gi, "")}
      />
      <div className="pb-8 sm:pb-24 lg:pb-24 lg:bg-heroGradient md:bg-heroGradient lg:min-h-screen lg:grid lg:items-center md:min-h-screen md:grid md:items-center">
        <div className="pt-8 lg:relative lg:py-8">
          <div className="lg:mx-auto px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-6xl lg:grid lg:grid-cols-2">
            <div>
              <h1
                dangerouslySetInnerHTML={{ __html: project.title }}
                className="mt-4 sm:mt-5 lg:mt-6"
              ></h1>
              <hr className="w-12 bg-transparent border-t-2 border-purple-600 mt-4 mb-6" />
              <h4
                dangerouslySetInnerHTML={{ __html: project.description }}
              ></h4>
              <table className="lg:mt-14 md:mt-14 mt-10 w-full table-fixed">
                <tbody>
                  <tr>
                    {project.opendata && (
                      <td className="align-top pb-4" colspan="2">
                        <dt className="label">Open Data Project</dt>
                        <dd className="max-w-fit">
                          <p
                            className="font-light text-sm"
                            dangerouslySetInnerHTML={{
                              __html: project.opendata,
                            }}
                          ></p>
                        </dd>
                      </td>
                    )}
                    {project.client && (
                      <td className="align-top pb-4">
                        <dt className="label">Client</dt>
                        <dd className="max-w-fit">{getClient()}</dd>
                      </td>
                    )}
                    {project.funder && (
                      <td className="align-top pb-4">
                        <dt className="label">Funder</dt>
                        <dd>{getFunder()}</dd>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="align-top pb-4">
                      <dl>
                        <dt className="label">Region</dt>
                        <dd className="max-w-fit">
                          <p className="font-light text-sm">
                            {project.regions.join(", ")}
                          </p>
                        </dd>
                      </dl>
                    </td>
                    <td className="align-top pb-4">
                      <dl>
                        <dt className="label">Year</dt>
                        <dd>
                          <p className="font-light text-sm">{project.year}</p>
                        </dd>
                      </dl>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-top pb-4">
                      <dl>
                        <dt className="label">Sectors</dt>
                        <dd>
                          <ul className="space-y-1">
                            {project.sectors.map(i => (
                              <li className="font-light text-sm" key={i}>
                                {i}
                              </li>
                            ))}
                          </ul>
                        </dd>
                      </dl>
                    </td>
                    <td className="align-top pb-4">
                      <dl>
                        <dt className="label">Solutions</dt>
                        <dd>
                          <ul className="space-y-1">
                            {project.solutions.map(i => (
                              <li className="font-light text-sm" key={i}>
                                {i}
                              </li>
                            ))}
                          </ul>
                        </dd>
                      </dl>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="py-12 sm:relative sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-5/12 lg:max-w-2xl lg:px-0 md:px-0 px-4">
            <div className="relative sm:max-w-3xl sm:mx-auto sm:px-0 lg:max-w-none lg:h-full">
              <img
                alt={project.title}
                className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:max-w-none"
                src={`https://storage.googleapis.com/keshif-website/Projects/${project.slug}/Main.png`}
              ></img>
              <div className="flex items-center mt-10 w-full">
                {project.website && (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={project.website}
                    style={{ fontWeight: "400" }}
                    className="btn-primary tracking-wider"
                  >
                    <FontAwesomeIcon icon={faExternalLinkSquare} />
                    &nbsp; Explore the data
                  </a>
                )}
                <a
                  href="#section"
                  className={
                    "lg:flex md:flex hidden flex-col items-center scrollAnimation " +
                    (isScrollVisible ? "enabled" : "")
                  }
                >
                  <FontAwesomeIcon size="2x" icon={faMouseAlt} />
                  <small className="tracking-widest mt-2 text-xxs">
                    SCROLL
                  </small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {project.impact && <Impact slug={project.slug} impact={project.impact} />}
      {project.testimonial && project.testimonial.text && (
        <Testimonial testimonial={project.testimonial} />
      )}
      {project.visuals && project.visuals.length > 0 && (
        <Visuals project={project} />
      )}
      {project.services && project.services.length > 0 && (
        <Services bgColor="bg-white" services={project.services} />
      )}
      {project.publications && (
        <Publications publications={project.publications} />
      )}
      {project.related_projects && (
        <Related projects={project.related_projects} />
      )}
    </Layout>
  )
}
