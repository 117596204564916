import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
  faExternalLinkSquare
} from "@fortawesome/pro-regular-svg-icons"

export default function Publications({ publications }) {
  return (
    <section className="lg:relative">
      <div className="container">
        <div className="py-12 sm:pt-20 lg:py-20 border-b border-gray-200">
          <h2>Publications</h2>
          <div className="mt-10 grid grid-cols-2 gap-5">
            {publications.map(i => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={i.link}
              >
              <div className="bg-gray-50 p-5 h-40 flex justify-between flex-col rounded-sm">
                <h4>{i.title}</h4>
                <div className="flex justify-between items-center">
                  <div
                    target="_blank"
                    rel="noopener noreferrer"
                    href={i.link}
                    className="text-sm text-purple-600 font-medium"
                  >
                    <FontAwesomeIcon className="mr-2" icon={faExternalLinkSquare} />
                    Read the article
                  </div>
                  <span className="text-xs text-gray-400">
                    Published at {i.name}
                  </span>
                </div>
              </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
